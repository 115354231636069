<template>
  <div class="page inPage PopupPage">
    <div class="position">
      <i class="lee-icon lee-icon-arrow-left" @click="back"></i>
      <div class="title">Toast</div>
    </div>
    <div class="pageBody">
      <lee-cell-group title="基础用法" inset>
        <lee-cell title="文字提示" is-link @click="handShowText"></lee-cell>
        <lee-cell title="加载提示" is-link @click="handShowLoading"></lee-cell>
        <lee-cell title="成功提示" is-link @click="handShowSuccess"></lee-cell>
        <lee-cell title="失败提示" is-link @click="handShowFail"></lee-cell>
        <lee-cell title="HTML提示" is-link @click="handShowHtml"></lee-cell>
      </lee-cell-group>
      <lee-cell-group title="自定义图标" inset>
        <lee-cell title="自定义图标" is-link @click="handShowIcon"></lee-cell>
        <lee-cell title="自定义图片" is-link @click="handShowImg"></lee-cell>
        <lee-cell title="自定义加载图标" is-link @click="handShowLoadingType"></lee-cell>
      </lee-cell-group>
      <lee-cell-group title="回调事件" inset>
        <lee-cell title="回调事件(onClose,onOpen)" is-link @click="handShowEvent"></lee-cell>
      </lee-cell-group>
      <lee-cell-group title="动态更新提示" inset>
        <lee-cell title="动态更新提示" is-link @click="handShowUpdate"></lee-cell>
      </lee-cell-group>
      <lee-cell-group title="使用 Toast 组件" inset>
        <lee-toast v-model="show" message="使用 Toast 组件" type="text"></lee-toast>
        <lee-toast v-model="show2" message="回调事件(onClose,onOpen) <br/>关闭后跳转" type="html" @onOpen="openHandle"
                   @onClose="closeHandle"></lee-toast>
        <lee-cell title="使用 Toast 组件" is-link @click="handShowComponent"></lee-cell>
        <lee-cell title="回调事件(onClose,onOpen)" is-link @click="handShowClose"></lee-cell>
      </lee-cell-group>
    </div>
    <div class="pageFooter"><img src="@/assets/logo.png"></div>
  </div>
</template>
<script setup>
import {ref} from 'vue'
import {useRouter} from 'vue-router'
import {showToast, closeToast} from '@/leeplus';

const show = ref(false)
const show2 = ref(false)
const router = useRouter()
const back = () => {
    router.push('/')
}
const handShowText = () => {
    showToast('提示内容')
}
const handShowLoading = () => {
    showToast({
        message: '加载中...',
        type: 'loading'
    })
}
const handShowSuccess = () => {
    showToast({
        message: '成功文案',
        type: 'success'
    })
}
const handShowFail = () => {
    showToast({
        message: '失败文案',
        type: 'fail'
    })
}
const handShowHtml = () => {
    showToast({
        message: '<p style="color: red;">士大夫士大夫</p>',
        type: 'html'
    })
}
const handShowIcon = () => {
    showToast({
        message: '自定义图标',
        icon: 'fabulous',
    })
}
const handShowImg = () => {
    showToast({
        message: '自定义图片',
        icon: 'https://docs.leeao82.com/img/eat-1.png',
    })
}
const handShowLoadingType = () => {
    showToast({
        message: '加载中...',
        type: 'loading',
        loadingType: 'spinner',
    })
}
const handShowEvent = () => {
    showToast({
        message: '关闭后跳转',
        type: 'success',
        onOpen: () => {
            console.log("打开了")
        },
        onClose: () => {
            console.log("关闭后跳转到首页")
            router.push('/')
        }
    })
}
const handShowUpdate = () => {
    let second = 3;
    showToast({
        duration: 0,
        type: 'loading',
        message: `倒计时 ${second} 秒`,
    })
    const timer = setInterval(() => {
        second--;
        if (second) {
            showToast({
                duration: 0,
                type: 'loading',
                message: `倒计时 ${second} 秒`,
            })
        } else {
            clearInterval(timer);
            closeToast();
        }
    }, 1000);
}
const handShowComponent = () => {
    show.value = true
}
const handShowClose = () => {
    show2.value = true
}
const openHandle = () => {
    console.log("打开了")
}
const closeHandle = () => {
    console.log("关闭后跳转到首页")
    router.push('/')
}


</script>
<style>
.PopupPage ul {
  display: flex;
  background: var(--lee-white);
}

.PopupPage ul li {
  width: 25%;
}

.PopupPage ul li .li {
  padding: 50% 0;
  height: 0;
  border-right: solid 1px var(--lee-gray-light);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.PopupPage ul li .li i {
  font-size: 28px;
}

.PopupPage ul .text {
  font-size: 12px;
  margin-top: 8px;
}


</style>
